body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: min(7vw, 7vh);
  line-height: 1.25;
}

.Stage {
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  inset: 0;
}

.Stage--rotate {
  transform-origin: 0 0;
  width: 100vh;
  height: 100vw;
  top: 50%;
  left: 50%;
  transform: rotate(90deg)translate(-50%, -50%);
}

.Stage--invert .Stage__overlay {
  -webkit-backdrop-filter: invert();
  backdrop-filter: invert();
}

.Stage--invert .Player {
  filter: hue-rotate(180deg);
}

.Stage__indicator {
  color: #fff;
  mix-blend-mode: difference;
  width: 1em;
  height: 1em;
  padding: .5em;
  position: fixed;
  top: 0;
  left: 0;
}

.Stage__indicator svg {
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity .2s;
}

.Stage__overlay {
  pointer-events: none;
  z-index: 99;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.Player {
  flex: 2;
  position: relative;
}

.Player__subtitles {
  pointer-events: none;
  text-align: center;
  color: #fff;
  mix-blend-mode: difference;
  padding: 5em 1em 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.Video {
  object-fit: cover;
  background-color: #000;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  transform: scale(1.005);
}

.Button {
  color: purple;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  width: 100%;
  height: 100%;
  padding: 2em;
  font-size: min(7vw, 7vh);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*# sourceMappingURL=index.2156b749.css.map */

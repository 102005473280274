$font-size: 7;
$button-color: purple;
$blur: 0.75em;

body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.25;
  font-size: min(#{$font-size}vw, #{$font-size}vh);
  -webkit-font-smoothing: antialiased;
}

.Stage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;

  &--rotate {
    width: 100vh;
    height: 100vw;
    top: 50%;
    left: 50%;
    transform: rotate(90deg) translate(-50%, -50%);
    transform-origin: top left;
  }

  &--invert {
    .Stage__overlay {
      backdrop-filter: invert(1);
    }

    .Player {
      filter: hue-rotate(180deg);
    }
  }

  &__indicator {
    position: fixed;
    top: 0;
    left: 0;
    color: white;
    padding: 0.5em;
    width: 1em;
    height: 1em;
    mix-blend-mode: difference;

    svg {
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 200ms;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 99;
  }
}

.Player {
  position: relative;
  flex: 2;

  &__subtitles {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    text-align: center;
    color: white;
    padding: 5em 1em 1em 1em;
    mix-blend-mode: difference;
  }
}

.Video {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  object-fit: cover;
  display: block;
  // Avoid 1px white line in some configurations
  transform: scale(1.005);
}

.Button {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: min(#{$font-size}vw, #{$font-size}vh);
  background-color: transparent;
  border: 0;
  color: $button-color;
  cursor: pointer;
  padding: 2em;
  width: 100%;
  height: 100%;
}
